import Vue from 'vue'

export default function patchBookLink({ data }) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/books/" + data.book_id + "/link"
    return Vue.http
        .patch(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json()).catch((r) => { throw r })
}