import Vue from 'vue'

export default function getOrdersGroups({ data }) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/orders/students/" + data.id + "/send"
    return Vue.http
        .put(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
}