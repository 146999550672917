<template>
  <div class="stat__totals-data">
    <div v-if="totals.hasOwnProperty('total_plan')">
      <span class="stat__totals__item">
        Plan: <strong>{{ totalPlan.toLocaleString() }} р. </strong>
      </span>
      <!-- <span class="stat__totals__item">
        Fact: <strong>{{ totals.total_fact.toLocaleString() }} р. </strong>
      </span>
      <span class="stat__totals__item">
        Paid: <strong>{{ totals.total_pays.toLocaleString() }} р. </strong>
      </span>
      <span
        class="stat__totals__item credit"
        :class="{ negative: totalDiff < 0 }"
      >
        Diff (paid - fact):
        <strong>{{ totalDiff.toLocaleString() }} р. </strong>
      </span> -->
      <vs-button
        type="flat"
        color="primary"
        v-if="type == 'student-group' && totalDiff != 0"
        @click="transferFromPopupActive = true"
        >Transfer</vs-button
      >
    </div>
    <div v-if="transferFromPopupActive">
      <vs-popup
        class="form-popup students-transfer-popup"
        classContent="students-transfer__popup-content"
        title="Transfer"
        :active.sync="transferFromPopupActive"
      >
        <transfer-from
          v-if="transferFromPopupActive"
          :valueToTransfer="totalDiff"
          typeFrom="group"
          :group="group"
          @closePopup="closePopup"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import transferFrom from '@/components/students/transfers/transferFrom.vue'
export default {
  props: ['totals', 'type', 'group', 'showGroupsAndTutorsSum'],
  data() {
    return {
      transferFromPopupActive: false,
    }
  },
  computed: {
    totalDiff: function() {
      return this.totals.total_pays - this.totals.total_fact
    },
    totalPlan: function() {
      if (this.showGroupsAndTutorsSum) {
        return this.totals.total_plan + this.totals.total_plan_tutor
      }
      return this.totals.total_plan
    },
  },
  components: {
    transferFrom,
  },
  methods: {
    closePopup: function() {
      this.transferFromPopupActive = false
    },
  },
}
</script>

<style lang="scss">
.stat__totals-data {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #ccc;
}
</style>
