<template>
  <div>
    <div class="left inline-part">
      <div class="header">
        <h2>From</h2>
        <vs-chip class="type-from">{{ balanceName }}</vs-chip>
        <vs-chip class="selected-sum-from" color="primary"
          >To transfer: <strong>{{ selectedSum }} р. </strong></vs-chip
        >
      </div>
      <div class="field">
        <vs-avatar icon="person"></vs-avatar>
        <span>{{ student.name }}</span>
      </div>
      <vs-select class="selectExample" label="Schoolyear" v-model="schoolyear">
        <vs-select-item
          :key="index"
          :value="item.id"
          :text="item.name"
          v-for="(item, index) in years"
        />
      </vs-select>
      <!-- <div v-if="type == 'free'" class="transfer-form-fields"> -->
      <div class="transfer-form-fields">
        <div class="field">
          <vs-input v-model="value" label="Value to transfer"></vs-input>
        </div>
      </div>
      <div v-if="type != 'free'" class="transfer-form-fields">
        <div>
          <vs-radio
            v-model="selected"
            :vs-value="order"
            v-for="(order, index) in orders"
            :key="index"
            vs-name="orig-order"
          >
            <vs-row vs-w="12">
              <vs-col vs-justify="left" vs-align="center" vs-lg="4">
                <div v-for="(i, index) in order.items" :key="index">
                  <vs-avatar
                    v-if="order.type == 'group'"
                    icon="people"
                    :color="i.color"
                  >
                  </vs-avatar>
                  <vs-avatar v-if="order.type == 'book'" icon="import_contacts">
                  </vs-avatar>
                  {{ i.name }}
                </div>
                <div v-if="order.type == 'lesson'">Tutor</div>
              </vs-col>
              <vs-col vs-justify="left" vs-align="center" vs-lg="3">
                <div>
                  {{
                    $moment(order.month).add("month", -1).format("MMMM YYYY")
                  }}
                </div>
              </vs-col>
              <vs-col vs-justify="left" vs-align="center" vs-lg="5">
                Balance:
                <span class="credit" :class="{ negative: order.balance < 0 }">
                  {{ order.balance }} р.
                </span>
              </vs-col>
            </vs-row>
          </vs-radio>
        </div>

        <!-- <vs-table multiple v-model="selected" :data="overpayOrders">
          <template slot="header">
            <h4>Orders</h4>
          </template>
          <template slot="thead">
            <vs-th> Item </vs-th>
            <vs-th> Balance </vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.items">
                <div v-for="(i, index) in tr.items" :key="index">
                  <vs-avatar
                    v-if="tr.type == 'group'"
                    icon="people"
                    :color="i.color"
                  >
                  </vs-avatar>
                  <vs-avatar v-if="tr.type == 'book'" icon="import_contacts">
                  </vs-avatar>
                  {{ i.name }}
                </div>
                <div v-if="tr.type == 'lesson'">Tutor</div>
              </vs-td>
              <vs-td>{{ $moment(tr.month).format("MMMM YYYY") }}</vs-td>
              <vs-td :data="tr.balance"> {{ tr.balance }} р. </vs-td>
            </vs-tr>
          </template>
        </vs-table> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["type", "student", "valueToTransfer", "orders"],
  data() {
    return {
      value: 0,
      selected: null,
      schoolyear: null,
    };
  },
  computed: {
    balanceName: function () {
      return `${this.type} balance`;
    },
    overpayOrders: function () {
      return this.orders.filter((o) => o.balance > 0);
    },
    selectedSum: function () {
      return this.value;
      // return this.selected.reduce((a, b) => a + b.balance, 0) || this.value;
    },
    ...mapState({
      years: (state) => state.years.years,
      curYear: (state) => state.years.currentSchoolYear,
    }),
  },
  watch: {
    overpayOrders: function (newV) {
      if (newV.length == 1) {
        this.selected = newV;
      }
    },
    selected: function (newV) {
      this.$emit("ordersFromUpdate", newV);
    },
    value: function (newV) {
      this.$emit("valueUpdate", newV);
    },
    schoolyear: function (newV) {
      this.selected = [];
      this.$emit("schoolyearFromUpdate", newV);
    },
  },
  created() {
    this.value = this.valueToTransfer || 0;
    this.schoolyear = this.curYear.id;
  },
};
</script>

<style lang="scss">
.transfer-form-fields {
  margin-top: 15px;
}
</style>