<template>
  <div>
    <div class="header">
      <h2>To</h2>
      <span v-if="orderTo">
        <vs-chip v-if="orderTo.type == 'group'" class="type-to">
          <vs-avatar icon="people" :color="orderTo.items[0].color"></vs-avatar>
          {{ orderTo.items[0].name }}
          <span> - {{ $moment(orderTo.month).format("MMMM YYYY") }}</span>
        </vs-chip>
        <vs-chip v-if="orderTo.type == 'book'" class="type-to">
          <vs-avatar icon="import_contacts"></vs-avatar>
          <span v-for="(item, index) in orderTo.items" :key="index">
            {{ item.name }}
          </span>
        </vs-chip>
        <vs-chip v-if="orderTo.type == 'lesson'" class="type-to">
          Tutor
        </vs-chip>
        <vs-chip color="primary" class="type-to" v-if="valueToTransfer">
          End value: {{ endValue }}
        </vs-chip>
      </span>
    </div>
    <div class="transfer-form-fields">
      <vs-select class="selectExample" label="Schoolyear" v-model="schoolyear">
        <vs-select-item
          :key="index"
          :value="item.id"
          :text="item.name"
          v-for="(item, index) in years"
        />
      </vs-select>
      <div class="field">
        <label for="">Student</label>
        <v-select
          label="name"
          :options="students"
          v-model="studentTo"
          :reduce="(item) => item.id"
          autocomplete="on"
          clearable
        >
          <template #option="{ name }">
            <vs-avatar icon="person"></vs-avatar>
            {{ name }}
          </template>
          <template #selected-option="{ name }">
            <vs-avatar icon="person" size="20px"></vs-avatar>
            {{ name }}
          </template>
        </v-select>
        <div v-if="!$v.studentTo.required" class="error">Field is required</div>
      </div>
      <div class="to-orders">
        <vs-tabs>
          <vs-tab label="Groups orders">
            <div class="">
              <vs-radio
                v-model="orderTo"
                :vs-value="order"
                v-for="(order, index) in groupOrders"
                :key="index"
                vs-name="dest-order"
              >
                <vs-row vs-w="12">
                  <vs-col vs-justify="left" vs-align="center" vs-lg="4">
                    <vs-avatar
                      icon="people"
                      :color="order.items[0].color"
                    ></vs-avatar>
                    {{ order.items[0].name }}
                  </vs-col>
                  <vs-col vs-justify="left" vs-align="center" vs-lg="3">
                    <div>
                      {{
                        $moment(order.month)
                          .add("month", -1)
                          .format("MMMM YYYY")
                      }}
                    </div>
                  </vs-col>
                  <vs-col vs-justify="right" vs-align="center" vs-lg="5">
                    Balance:
                    <span
                      class="credit"
                      :class="{ negative: order.balance < 0 }"
                    >
                      {{ order.balance }} р.
                    </span>
                  </vs-col>
                </vs-row>
              </vs-radio>
            </div>
          </vs-tab>
          <vs-tab label="Book orders">
            <div class="">
              <vs-radio
                v-model="orderTo"
                :vs-value="order"
                v-for="(order, index) in bookOrders"
                :key="index"
                vs-name="dest-order"
              >
                <vs-row vs-w="12">
                  <vs-col vs-justify="left" vs-align="center" vs-lg="7">
                    <vs-avatar icon="import_contacts"></vs-avatar>
                    <span v-for="(item, index) in order.items" :key="index">
                      {{ item.name }}
                    </span>
                  </vs-col>
                  <vs-col>
                    <div>
                      {{ $moment(order.month).format("MMMM YYYY") }}
                    </div>
                  </vs-col>
                  <vs-col vs-justify="right" vs-align="center" vs-lg="5">
                    Balance: {{ order.balance }} р.
                  </vs-col>
                </vs-row>
              </vs-radio>
            </div>
          </vs-tab>
          <vs-tab label="Lesson orders">
            <div class="">
              <vs-radio
                v-model="orderTo"
                :vs-value="order"
                v-for="(order, index) in lessonOrders"
                :key="index"
                vs-name="dest-order"
              >
                <vs-row vs-w="12">
                  <vs-col vs-justify="left" vs-align="center" vs-lg="7">
                    Tutor
                  </vs-col>
                  <vs-col vs-justify="right" vs-align="center" vs-lg="5">
                    Balance: {{ order.balance }} р.
                  </vs-col>
                </vs-row>
              </vs-radio>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
export default {
  props: ["orders", "student", "valueToTransfer"],
  data() {
    return {
      studentTo: this.student.id,
      orderTo: null,
      schoolyear: null,
    };
  },
  components: {
    "v-select": vSelect,
  },
  computed: {
    ...mapState({
      students: (state) => state.students.students,
      years: (state) => state.years.years,
      curYear: (state) => state.years.currentSchoolYear,
    }),
    groupOrders: function () {
      return this.orders.filter((o) => o.type == "group");
    },
    bookOrders: function () {
      return this.orders.filter((o) => o.type == "book");
    },
    lessonOrders: function () {
      return this.orders.filter((o) => o.type == "lesson");
    },
    endValue: function () {
      if (this.orderTo && this.valueToTransfer) {
        return this.orderTo.balance + this.valueToTransfer;
      } else if (!this.valueToTransfer) {
        return this.orderTo.balance;
      }
      return 0;
    },
  },
  validations: {
    studentTo: {
      required,
    },
  },
  watch: {
    studentTo: function (newV) {
      this.$emit("updateStudent", newV);
    },
    orderTo: function (newV) {
      this.$emit("orderToUpdate", newV);
    },
    schoolyear: function (newV) {
      this.$emit("schoolyearToUpdate", newV);
    },
  },
  created() {
    this.schoolyear = this.curYear.id;
  },
};
</script>

<style lang="scss">
</style>