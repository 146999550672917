<template>
  <div>
    <div class="field" v-if="!edit">
      <label for="">Book</label>
      <v-select
        label="name"
        :options="allBooks"
        v-model="book"
        :reduce="item => item.id"
        autocomplete="on"
        clearable
        placeholder="Select book"
        :class="{ error: !$v.book.required }"
      >
        <template #option="{ name }">
          <vs-icon icon="import_contacts"></vs-icon>
          {{ name }}
        </template>
        <template #selected-option="{ name }">
          <vs-icon icon="import_contacts"></vs-icon>
          {{ name }}
        </template>
      </v-select>
      <div v-if="!$v.book.required" class="error">Field is required</div>
    </div>
    <div class="field" v-if="!edit">
      <label for="">Schoolyear</label>
      <v-select
        label="name"
        :options="years"
        v-model="schoolyear_id"
        :reduce="item => item.id"
        autocomplete="on"
        clearable
        placeholder="Select schoolyear"
      >
        <template #option="{ name }">
          {{ name }}
        </template>
        <template #selected-option="{ name }">
          {{ name }}
        </template>
      </v-select>
      <div v-if="!$v.schoolyear_id.required" class="error">
        Field is required
      </div>
    </div>
    <div class="field" v-if="!edit">
      <label for="">Group</label>
      <v-select
        label="name"
        :options="groupsToSelect"
        v-model="selectedGroup"
        :reduce="item => item.id"
        autocomplete="on"
        clearable
        placeholder="Select one group"
      >
        <template #option="{ name, color }">
          <vs-avatar icon="people" :color="color" size="small"></vs-avatar>
          {{ name }}
        </template>
        <template #selected-option="{ name, color }">
          <vs-avatar icon="people" :color="color" size="small"></vs-avatar>
          {{ name }}
        </template>
      </v-select>
      <div v-if="!$v.selectedGroup.required" class="error">
        Field is required
      </div>
    </div>
    <div class="field" v-if="!edit">
      <label for="">Students</label>
      <v-select
        label="name"
        :options="students"
        v-model="selectedStudents"
        :reduce="item => item.id"
        clearable
        multiple
        placeholder="Select students"
        :selectable="studentSelectableHandler"
      >
        <template #option="{ name }">
          <vs-avatar icon="person" size="small"></vs-avatar>
          {{ name }}
        </template>
        <template #selected-option="{ name }">
          <vs-avatar icon="person" size="small"></vs-avatar>
          {{ name }}
        </template>
      </v-select>
      <vs-button size="small" type="flat" @click="selectAllStudentsHandler"
        >Select all ({{ students.length || 0 }})</vs-button
      >
      <vs-button
        size="small"
        type="flat"
        color="danger"
        @click="selectedStudents = []"
        >Remove all</vs-button
      >
      <div v-if="!$v.selectedStudents.required" class="error">
        Field is required
      </div>
    </div>
    <div class="field">
      <label for="">Book type</label>
      <vs-select v-model="bookTypeId">
        <vs-select-item
          :key="bookType.id"
          :value="bookType.id"
          :text="bookType.name"
          v-for="bookType in bookTypes"
        />
      </vs-select>
    </div>
    <div class="field" v-if="!edit">
      <label for="">Free book</label>
      <vs-checkbox v-model="free"
        >Free book (or book only for test)</vs-checkbox
      >
    </div>
    <div class="field">
      <label for="">Book with tests</label>
      <vs-checkbox v-model="withTests">With tests</vs-checkbox>
    </div>
    <div class="field" v-if="!free">
      <vs-input
        size="large"
        label="Price"
        v-model="price"
        @keypress.enter="saveHandler"
      ></vs-input>
    </div>

    <div class="save-popup-button">
      <vs-button ref="loadableButton" icon="save" @click="saveHandler"
        >Save</vs-button
      >
    </div>
  </div>
</template>

<script>
import getBooks from '@/api/books/getBooks'
import setBookLink from '@/api/books/setBookLink'
import patchBookLink from '@/api/books/patchBookLink'
import { mapState } from 'vuex'
import vSelect from 'vue-select'
import { required } from 'vuelidate/lib/validators'

export default {
  props: ['groupFrom', 'studentFrom', 'editBook'],
  data() {
    let data = {
      allBooks: [],
      search: '',
      book: null,
      selectedGroup: null,
      selectedStudents: [],
      price: 0,
      schoolyear_id: null,
      edit: false,
      free: false,
      withTests: true,
      bookTypeId: null,
    }
    if (this.editBook && this.editBook.book) {
      data = { ...data, ...this.editBook }
      data.edit = true
    }
    return data
  },
  validations: {
    book: {
      required,
    },
    selectedGroup: {
      required,
    },
    selectedStudents: {
      required,
    },
    schoolyear_id: {
      required,
    },
  },
  components: {
    vSelect,
  },
  computed: {
    filteredBooks: function() {
      if (this.search) {
        const books = this.allBooks.filter(b =>
          b.name.toLowerCase().includes(this.search.toLowerCase())
        )
        return books
      }
      return this.allBooks
    },
    ...mapState({
      years: state => state.years.years,
      currentYear: state => state.years.currentSchoolYear,
      books: state => state.books.books,
      groups: state => state.groups.groups,
      group: state => state.groups.singleGroup,
      bookTypes: state => state.books.bookTypes,
    }),
    students: function() {
      if (this.studentFrom) {
        return [this.studentFrom]
      } else {
        if (this.selectedGroup) {
          const students = this.groups.find(g => g.id == this.selectedGroup)
            .students
          if (students) {
            return students.filter(
              s => s.is_active == true || s.is_active == null
            )
          }
          return []
        }
      }
      return []
    },
    groupsToSelect: function() {
      if (this.studentFrom && this.studentFrom.groups) {
        return this.studentFrom.groups.filter(
          g => g.schoolyear.id == this.schoolyear_id
        )
      }
      return this.groups
    },
  },
  methods: {
    selectAllStudentsHandler: function() {
      this.selectedStudents = this.students.map(s => s.id)
    },
    studentSelectableHandler: function(option) {
      if (this.students.indexOf(option.student_id) >= 0) return false
      return true
    },
    saveHandler: function() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$vs.notify({
          title: 'Error',
          text: `Required fields is empty`,
          color: 'danger',
          position: 'top-right',
        })
        return
      }
      this.$vs.loading({
        background: 'primary',
        color: '#fff',
        container: this.$refs.loadableButton.$el,
        scale: 0.45,
      })
      const {
        price,
        schoolyear_id,
        book,
        selectedGroup,
        selectedStudents,
        free,
        withTests,
        bookLinkId,
        bookTypeId,
      } = this
      const data = {
        price,
        schoolyear_id,
        free,
        with_tests: withTests,
        book_id: book,
        group_id: selectedGroup,
        student_id: selectedStudents,
        book_link_id: bookLinkId,
        book_in_out_type_id: bookTypeId,
      }
      // создаем или редактируем
      const method = this.edit ? patchBookLink : setBookLink
      method
        .call(this, { data })
        .then(() => {
          this.$emit('bookSaved', data)
          this.$vs.loading.close(this.$refs.loadableButton.$el)
          this.book = null
          this.selectedGroup = null
          this.selectedStudents = []
          this.price = 0
          this.free = false
          this.withTests = true
          this.book_in_out_type_id = null
          // this.schoolyear_id = null;
        })
        .catch(e => {
          this.$vs.notify({
            title: 'Book save error',
            text: `${e.body.message}`,
            color: 'danger',
            position: 'top-right',
          })
          this.$vs.loading.close(this.$refs.loadableButton.$el)
        })
    },
  },
  created: function() {
    getBooks.call(this).then(data => {
      if (data.status == 'OK') {
        this.allBooks = data.data
      }
    })
    if (this.group.id) {
      this.selectedGroup = this.group.id
      this.schoolyear_id = this.group.schoolyear.id
    }
    if (this.studentFrom) {
      this.selectedStudents = [this.studentFrom.id]
    }
    this.$store.dispatch('books/getBookTypesAction')
  },
  watch: {
    // groups: function (newV) {
    //   if (newV) {
    //     this.schoolyear_id = this.currentYear.id;
    //   }
    // },
    schoolyear_id: function(newV) {
      if (newV) {
        const data = {
          schoolyear_id: this.schoolyear_id,
        }
        this.$store.dispatch('groups/getGroupsAction', { data })
      }
    },
    currentYear: function(newV) {
      if (newV) {
        this.schoolyear_id = newV.id
      }
    },
  },
}
</script>

<style scoped>
.field {
  flex: 1;
}
.save-popup-button {
  margin-top: 35px;
}
</style>
