<template>
  <div class="transfer-form">
    <vs-row vs-w="12">
      <vs-col vs-justify="center" vs-align="center" vs-lg="5">
        <origin-orders-form
          :type="typeFrom"
          :student="student"
          :valueToTransfer="valueToTransfer"
          :orders="origOrders"
          @ordersFromUpdate="ordersFromUpdateHandler"
          @valueUpdate="valueUpdateHandler"
          @schoolyearFromUpdate="schoolyearFromUpdateHandler"
        />
      </vs-col>
      <vs-col
        vs-justify="center"
        vs-align="center"
        vs-lg="1"
        class="center-col"
      >
        <vs-icon icon="arrow_right_alt"></vs-icon>
      </vs-col>
      <vs-col
        vs-justify="center"
        vs-align="center"
        vs-lg="6"
        class="destination-orders-form"
      >
        <dest-orders-form
          :orders="destOrders"
          :student="to.studentTo"
          @updateStudent="updateStudentToHandler"
          @orderToUpdate="orderToUpdateHandler"
          @schoolyearToUpdate="schoolyearToUpdateHandler"
          v-if="to.studentTo"
        />
      </vs-col>
    </vs-row>
    <div class="buttons">
      <vs-button
        @click="saveHandler"
        ref="loadableButton"
        id="button-with-loading"
        class="vs-con-loading__container"
        :disabled="
          saving ||
          !from.ordersFrom ||
          !to.orderTo ||
          (typeFrom == 'free' && !to.orderTo)
        "
        >Save</vs-button
      >
      <vs-button type="flat" color="dark" @click="$emit('closePopup')"
        >Cancel</vs-button
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import "vue-select/dist/vue-select.css";
import getSingleStudents from "@/api/students/getSingleStudents";
import getOrdersStudents from "@/api/orders/getOrdersStudents.js";
import OriginOrdersForm from "./originOrdersForm.vue";

import getOrdersGroupsByStudent from "@/api/orders/getOrdersGroupsByStudent";
import getStudentBooks from "@/api/books/getStudentBooks";
import getOrdersTutorByStudent from "@/api/orders/getOrdersTutorByStudent";
import DestOrdersForm from "./destOrdersForm.vue";

export default {
  name: "transferFrom",
  props: ["valueToTransfer", "typeFrom", "group"],
  data() {
    return {
      origOrders: [],
      destOrders: [],
      from: {
        value: this.valueToTransfer,
        ordersFrom: null,
        schoolyearFrom: null,
      },
      to: {
        studentTo: null,
        orderTo: null,
        schoolyearTo: null,
      },
      origin_schoolyear: null,
      dest_schoolyear: null,
      saving: false,
    };
  },
  components: {
    OriginOrdersForm,
    DestOrdersForm,
  },
  computed: {
    ...mapState({
      student: (state) => state.students.singleStudent,
      students: (state) => state.students.students,
      balance: (state) => state.students.balance,
      curYear: (state) => state.years.currentSchoolYear,
    }),
  },
  created: async function () {
    if (!this.student.id) {
      console.error("origin student not defined");
      return;
    }
    this.to.schoolyearTo = this.curYear.id;
    this.from.schoolyearFrom = this.curYear.id;
    if (!this.students.length) {
      await this.$store.dispatch("students/getStudentsAction");
    }
    if (this.student.id) {
      this.to.studentTo = this.student;
      // получаем исходные расчеты по типу
      await this.getOriginOrders();
      // получаем целевые расчеты по типу
      await this.getDestinationOrders();
    }
  },
  methods: {
    _groupOrdersQuery: async function (student, schoolyear_id, groups) {
      const { id } = student;

      // const groups = student.groups.map((g) => g.id);
      const orders = await getOrdersGroupsByStudent.call(this, {
        student: id,
        groups,
        schoolyear_id,
      });
      if (orders.status != "OK") {
        console.error(orders.message);
        return;
      }
      let output = [];
      for (var key in orders.data) {
        output = [
          ...output,
          ...orders.data[key].map((o) => {
            return {
              type: "group",
              id: o.id,
              items: [o.group],
              value: o.value,
              balance: o.balance,
              month: new Date(o.year, o.month, 1),
            };
          }),
        ];
      }
      return output;
    },
    _bookOrdersQuery: async function (student, schoolyear_id) {
      const { id } = student;
      const orders = await getStudentBooks.call(this, {
        student_id: id,
        schoolyear_id,
      });
      if (orders.status != "OK") {
        console.error(orders.message);
        return;
      }
      let output = [];
      orders.data.forEach((o) => {
        output = [
          ...output,
          ...o.orders.map((o) => {
            return {
              type: "book",
              id: o.id,
              items: o.books,
              value: o.value,
              balance: o.balance,
              month: new Date(o.month * 1000),
            };
          }),
        ];
      });
      return output;
    },
    _lessonOrdersQuery: async function (student, schoolyear_id) {
      const { id } = student;
      const orders = await getOrdersTutorByStudent.call(this, {
        student: id,
        schoolyear_id,
      });
      if (orders.status != "OK") {
        console.error(orders.message);
        return;
      }
      return orders.data.map((o) => {
        return {
          type: "lesson",
          id: o.id,
          items: [],
          value: o.value,
          balance: o.balance,
          month: new Date(o.year, o.month, 1),
        };
      });
    },
    getOriginOrders: async function () {
      // получаем расчеты по типу
      if (this.typeFrom == "group") {
        // получаем расчеты по группам
        let groups = [];
        if (this.group) groups.push(this.group.id);

        this.origOrders = await this._groupOrdersQuery(
          this.student,
          this.from.schoolyearFrom,
          groups
        );
      }
      if (this.typeFrom == "book") {
        // получаем расчеты по учебника
        this.origOrders = await this._bookOrdersQuery(
          this.student,
          this.from.schoolyearFrom
        );
      }
      if (this.typeFrom == "lesson") {
        // получаем расчеты по учебника
        this.origOrders = await this._lessonOrdersQuery(
          this.student,
          this.from.schoolyearFrom
        );
      }
    },
    getDestinationOrders: async function () {
      // получаем расчеты по группам
      this.destOrders = [
        ...(await this._groupOrdersQuery(
          this.to.studentTo,
          this.to.schoolyearTo,
          []
        )),
        ...(await this._bookOrdersQuery(
          this.to.studentTo,
          this.to.schoolyearTo
        )),
        ...(await this._lessonOrdersQuery(
          this.to.studentTo,
          this.to.schoolyearTo
        )),
      ];
    },
    saveHandler: function () {
      const { to, from, value, typeFrom } = this;
      const data = {
        type: typeFrom,
        parent: from.ordersFrom.id,
        student_id_from: this.student.id,
        student_id_to: to.studentTo.id,
        schoolyear_id: from.schoolyearFrom,
        schoolyear_id_to: to.schoolyearTo,
        type_to: to.orderTo.type,
        order_id: to.orderTo.id,
        value,
      };
      this.saving = true;
      this.$vs.loading({
        background: "primary",
        color: "#fff",
        container: this.$refs.loadableButton.$el,
        scale: 0.45,
      });

      this.$store.dispatch("payments/setTransferAction", { data }).then(() => {
        this.$vs.notify({
          title: "Transfer saved",
          text: ``,
          color: "success",
          position: "top-right",
        });
        this.$store
          .dispatch("students/getSingleStudentAction", {
            id: this.student.id,
            schoolyear_id: this.curYear.id,
          })
          .then(() => {
            this.$emit("closePopup");
          });
        this.saving = false;
      });
    },

    updateStudentToHandler: async function (studentToId) {
      let studentResponse = await getSingleStudents.call(
        this,
        studentToId,
        this.to.schoolyearTo
      );
      if (studentResponse.status != "OK") {
        console.error("student error");
      }
      this.to.studentTo = studentResponse.data;
      await this.getDestinationOrders();
    },
    ordersFromUpdateHandler: function (order) {
      this.from.ordersFrom = order;
    },
    valueUpdateHandler: function (value) {
      this.value = value;
    },
    orderToUpdateHandler: function (order) {
      this.to.orderTo = order;
    },
    schoolyearFromUpdateHandler: async function (schoolyear) {
      this.from.schoolyearFrom = schoolyear;
      this.origOrders = [];
      await this.getOriginOrders();
    },
    schoolyearToUpdateHandler: async function (schoolyear) {
      this.to.schoolyearTo = schoolyear;
      this.destOrders = [];
      await this.getDestinationOrders();
    },
    getGroupOrders: async function (student, groups) {
      let studentOrders = [];
      for (let g of groups) {
        const orders = await getOrdersStudents.call(this, {
          student_id: student.id,
          group_id: g,
        });
        if (orders.status == "OK") {
          studentOrders = [
            ...studentOrders,
            ...orders.data.map((o) => {
              const { id, group, year, month, balance } = o;
              const date = this.$moment({ year, month: month - 1, day: 1 });
              return {
                type: this.typesTo,
                id,
                name: `Group ${group.name} (${balance}р.) - ${date.format(
                  "MMMM YYYY"
                )}`,
                color: group.color,
                date,
              };
            }),
          ];
        }
      }
      return studentOrders;
    },
  },
};
</script>

<style lang="scss">
.students-transfer-popup {
  .vs-popup {
    width: 60%;
    height: 100%;
  }
  .students-transfer__popup-content {
    // overflow: unset;
  }
}
.transfer-form {
  .header {
    display: flex;
    flex-flow: row nowrap;
    .type-from,
    .type-to {
      margin-left: 15px;
      float: none;
    }
  }
  .vs-input,
  .con-select {
    width: 100%;
    input {
      padding: 12px;
    }
  }
  .center-col {
    text-align: center;
  }
  .vs-col,
  .buttons {
    padding: 15px;
  }
  .vs-radio--label {
    display: block;
    width: 100%;
  }
  .destination-orders-form {
    & > div {
      width: 100%;
    }
  }
  .vs-col {
    display: flex;
    align-items: flex-start !important;
  }
  .credit {
    font-weight: bold;
  }
  .buttons {
    position: sticky;
    bottom: -1.2rem;
    background-color: #fff;
  }
}
</style>